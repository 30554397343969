<template>
	<v-btn
		:color="props?.color"
		:variant="props.variant || 'flat'"
		:size="props.size"
		:disabled="props.disabled"
		:block="props.block"
	>
		<div
			v-if="props?.loading"
			class="loading"
		>
			<Icon
				name="loading"
				filled
			/>
		</div>
		<slot />
	</v-btn>
</template>

<script lang="ts" setup>
import Icon from "@/components/Icon/index.vue";

/**
 * @deprecated use Button from @tengiva/components
 */

interface ButtonProps {
	color?: "primary" | "secondary" | "error";
	variant?: "flat" | "outlined";
	size?: "large";
	disabled?: boolean;
	block?: boolean;
	loading?: boolean;
}

const props = defineProps<ButtonProps>();
</script>

<style lang="scss" scoped>
.btn-box-shadow {
	box-shadow: 0px 0px 3px 1px #0c7ce6;
}

.v-btn:focus-visible::after {
	@apply opacity-0;
}
.v-btn:focus-visible {
	:deep(.v-btn__overlay) {
		@apply opacity-0;
	}
}

.v-btn {
	:deep(.v-btn__content) {
		@apply normal-case tracking-normal text-[16px];
	}
}

// FLAT
.v-btn--variant-flat {
	&:hover {
		@apply bg-blue-300;
	}
	&:focus {
		:deep(.v-btn__underlay) {
			@apply border-[2px] border-[#ffffff] rounded-[4px];
		}
		@apply btn-box-shadow;
	}
}

// OUTLINED
.v-btn--variant-outlined {
	@apply border-none;
	box-shadow: 0 0 0 1px #cdcdd2;
	&:hover {
		:deep(.v-btn__overlay) {
			@apply opacity-0;
		}
		@apply border-neutral-100 bg-neutral-100;
	}
	&:focus {
		@apply btn-box-shadow bg-[#ffffff] border-[#ffffff];
	}
}

.v-btn--disabled {
	@apply bg-neutral-50 opacity-100 shadow-none #{!important};

	:deep(.v-btn__overlay) {
		@apply opacity-0 #{!important};
	}

	:deep(.v-btn__content) {
		@apply text-neutral-200;
	}
}

.loading {
	@apply h-[24px] w-[24px];
	:deep(.icon) {
		transform-origin: center;
		animation: button-loading 1.2s linear infinite;
	}
}

@keyframes button-loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
